// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=VT323);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
h1,
h2,
h3,
h4,
p,
a {
  color: #e0e2f4;
}

body,
body {
  background: #0414a7;
}

.container {
  width: 90%;
  margin: auto;
  max-width: 640px;
}

.bsod {
  padding-top: 10%;
}
.bsod .neg {
  text-align: center;
  color: #0414a7;
}
.bsod .neg .bg {
  background: #aaaaaa;
  padding: 0 15px 2px 13px;
}
.bsod .title {
  margin-bottom: 50px;
}
.bsod .nav {
  margin-top: 35px;
  text-align: center;
}
.bsod .nav .link {
  text-decoration: none;
  padding: 0 9px 2px 8px;
}
.bsod .nav .link:hover, .bsod .nav .link:focus {
  background: #aaaaaa;
  color: #0414a7;
}`, "",{"version":3,"sources":["webpack://./src/css/error.scss"],"names":[],"mappings":"AASA;;;;;;;EAMI,cAbS;AAOb;;AAQA;;EAOO,mBApBA;AAUP;;AAYA;EACE,UAAA;EACA,YAAA;EACA,gBAAA;AATF;;AAYA;EACE,gBAAA;AATF;AAWE;EACE,kBAAA;EACA,cAjCG;AAwBP;AAWI;EACE,mBArCC;EAsCD,wBAAA;AATN;AAYE;EAAS,mBAAA;AATX;AAUE;EACE,gBAAA;EACA,kBAAA;AARJ;AAUI;EACE,qBAAA;EACA,sBAAA;AARN;AAUM;EAEE,mBApDD;EAqDC,cApDD;AA2CP","sourcesContent":["@import 'https://fonts.googleapis.com/css?family=VT323';\n\n$light-grey: #e0e2f4;\n$grey: #aaaaaa;\n$blue: #0414a7;\n\n$base-font-size: 20px;\n$font-stack: 'VT323', monospace;\n\nbody,\nh1,\nh2,\nh3,\nh4,\np,\na { color: $light-grey; }\n\nbody,\n// p { font: normal #{$base-font-size}/1.25rem $font-stack; }\n// h1 { font: normal 2.75rem/1.05em $font-stack; }\n// h2 { font: normal 2.25rem/1.25em $font-stack; }\n// h3 { font: lighter 1.5rem/1.25em $font-stack; }\n// h4 { font: lighter 1.125rem/1.2222222em $font-stack; }\n\nbody { background: $blue; }\n\n.container {\n  width: 90%;\n  margin: auto;\n  max-width: 640px;\n}\n\n.bsod {\n  padding-top: 10%;\n  \n  .neg {\n    text-align: center;\n    color: $blue;\n    \n    .bg {\n      background: $grey;\n      padding: 0 15px 2px 13px;\n    }\n  }\n  .title { margin-bottom: 50px; }\n  .nav {\n    margin-top: 35px;\n    text-align: center;\n    \n    .link {\n      text-decoration: none;\n      padding: 0 9px 2px 8px;\n      \n      &:hover,\n      &:focus {\n        background: $grey;\n        color: $blue;\n      }\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
